import React, { useEffect, useContext, useState, useCallback } from 'react';
import { Panel, PanelHeader, PanelBody } from './../components/panel/panel.jsx';
import { AppSettings } from './../config/app-settings.js';
import { ImageUpload } from './ImageUpload.js';
import TestImage from './TestImage.js';

function Dashboard() {
  const context = useContext(AppSettings);

  useEffect(() => {
    context.handleSetAppSidebarNone(true);
    return () => {
      context.handleSetAppSidebarNone(true);
    };
	}, []);

  const [srcPath, setSrcPath] = useState("");

  const fileUpload = e => {
    debugger
    const [file] = e.files
    if (file) {
      setSrcPath(URL.createObjectURL(file));
    }
  }
  const [imgData, setImgData] = React.useState()
  const pasteImg = async ()=> {
    try {
        const clipboardItems = await navigator.clipboard.read()
        const blobOutput = await clipboardItems[0].getType('image/png')
        const data = URL.createObjectURL(blobOutput)
        setImgData(data)
    } catch(e) {
        console.log(e);
    }
}

// handle what happens on key press
const handleKeyPress = useCallback((event) => {
  console.log(`Key pressed: ${event.key}`);
  pasteImg();
}, []);

useEffect(() => {
  // attach the event listener
  document.addEventListener('paste', handleKeyPress);

  // remove the event listener
  return () => {
    document.removeEventListener('paste', handleKeyPress);
  };
}, [handleKeyPress]);

  return (
    <div>
      <Panel>
        <PanelBody>
          <a onClick={pasteImg}>singh</a>
          <img src={imgData} />
          {/* <TestImage /> */}
          <p style={{
            padding: "20px",
            textAlign: "center",
          }}>
            <ImageUpload />
          <i class="fa fa-cloud-upload fa-5x"></i>
          </p>
        </PanelBody>
      </Panel>

    </div>
  );
}

export default Dashboard;
